// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--0rktT";
export var bannerC = "index-module--bannerC--Z9tu2";
export var bgLineLeft = "index-module--bgLineLeft--QaxRi";
export var bgLineRight = "index-module--bgLineRight--yenX8";
export var bgcircle = "index-module--bgcircle--RilsD";
export var bs = "index-module--bs--qC+Q3";
export var header = "index-module--header--gpWDr";
export var innerCarousel = "index-module--inner-carousel--LaEyM";
export var layertext = "index-module--layertext--c4rtj";
export var logo = "index-module--logo--ItZjJ";
export var logoCarousel = "index-module--logoCarousel--nDzwl";
export var logoImage = "index-module--logoImage--gjCIA";
export var logoSlider = "index-module--logoSlider--+AOBb";
export var mb = "index-module--mb--VKJPG";
export var mt = "index-module--mt--e-d6a";
export var pointprimary = "index-module--pointprimary--hZ9Iw";
export var pointsecondary = "index-module--pointsecondary--VwwPV";
export var test = "index-module--test--O55u9";
export var testimonial = "index-module--testimonial--oBMu2";
export var testimonialSlider = "index-module--testimonialSlider--33HYT";
export var tp = "index-module--tp--hlz5-";
export var verticalCarouselImage = "index-module--verticalCarouselImage--f8LHj";
export var verticalCarouselImageContainer = "index-module--verticalCarouselImageContainer--JV9sT";
export var verticalCarouselRow = "index-module--verticalCarouselRow--Nobyx";